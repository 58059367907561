var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isApproved ? _c('v-alert', {
    attrs: {
      "text": "",
      "type": "success"
    }
  }, [_vm._v("Søknaden om tilretteleggingstilskudd er "), _c('strong', [_vm._v("godkjent")]), _vm._v(".")]) : _vm._e(), _vm.isRejected ? _c('v-alert', {
    attrs: {
      "text": "",
      "type": "error"
    }
  }, [_vm._v("Søknaden om tilretteleggingstilskudd er "), _c('strong', [_vm._v("avslått")]), _vm._v(".")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }