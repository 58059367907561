var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-textarea', {
    attrs: {
      "label": _vm.label,
      "outlined": "",
      "prepend-inner-icon": "mdi-comment",
      "persistent-hint": "",
      "rows": "3",
      "hint": _vm.hint,
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }