var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": "",
      "disabled": _vm.disabled
    },
    on: {
      "input": _vm.validate
    }
  }, [_c('v-row', [_c('v-col', [_vm._v(" Søker: "), _vm.memberOrganization ? _c('strong', [_vm._v(_vm._s(_vm.memberOrganization.name))]) : _vm._e()])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Målgruppe",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.targetGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "targetGroup", $$v);
      },
      expression: "grantValues.targetGroup"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "required",
    attrs: {
      "label": "Sted",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.location,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "location", $$v);
      },
      expression: "grantValues.location"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Antall timer",
      "hide-details": "auto"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.numberOfHours,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "numberOfHours", _vm._n($$v));
      },
      expression: "grantValues.numberOfHours"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": "Antall deltakere",
      "hide-details": "auto"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.numberOfParticipants,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "numberOfParticipants", _vm._n($$v));
      },
      expression: "grantValues.numberOfParticipants"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "required",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Startdato",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "startDate", $$v);
      },
      expression: "grantValues.startDate"
    }
  })], 1), _c('v-col', {
    staticClass: "required",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('BaseDatePicker', {
    attrs: {
      "label": "Sluttdato",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty,
      "forceEndOfDate": true,
      "max": _vm.latestDateOfCourseYear
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "endDate", $$v);
      },
      expression: "grantValues.endDate"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    staticClass: "required",
    attrs: {
      "label": "Beskrivelse av tiltaket det søkes om midler til",
      "outlined": "",
      "prepend-inner-icon": "mdi-comment-outline",
      "rows": "4",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.applicationDescription,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "applicationDescription", $$v);
      },
      expression: "grantValues.applicationDescription"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-textarea', {
    staticClass: "required",
    attrs: {
      "label": "Beskrivelse av tilleggskostnaden tilrettelegging medfører",
      "outlined": "",
      "prepend-inner-icon": "mdi-comment-outline",
      "rows": "4",
      "hide-details": "auto",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.grantValues.descriptionOfAdditionalCost,
      callback: function callback($$v) {
        _vm.$set(_vm.grantValues, "descriptionOfAdditionalCost", $$v);
      },
      expression: "grantValues.descriptionOfAdditionalCost"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }