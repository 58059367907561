var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cards"
  }, _vm._l(_vm.data.filter(function (item) {
    return item.value;
  }), function (item, i) {
    return _c('BaseIconCard', _vm._b({
      key: i,
      attrs: {
        "link": false,
        "captionAbove": "",
        "dense": ""
      }
    }, 'BaseIconCard', item, false));
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }