
import { computed, defineComponent } from "@vue/composition-api";
import { formatDate } from "@/shared/helpers/dateHelpers";
import { commonProps } from "./commonProps";
import BaseIconCard from "@/components/shared/BaseIconCard.vue";

export default defineComponent({
  name: "GrantApplicationDetails",
  props: { ...commonProps },
  components: { BaseIconCard },
  setup(props) {
    return {
      data: computed(() => [
        {
          headline: "Søker",
          value: props.memberOrganization?.name,
        },
        {
          headline: "Målgruppe",
          value: props.facilitationGrant.targetGroup,
        },
        {
          headline: "Sted",
          value: props.facilitationGrant.location,
        },
        {
          headline: "Antall timer",
          value: props.facilitationGrant.numberOfHours,
        },
        {
          headline: "Antall deltakere",
          value: props.facilitationGrant.numberOfParticipants,
        },
        {
          headline: "Varighet",
          value: `${formatDate(props.facilitationGrant.startDate)}–${formatDate(props.facilitationGrant.endDate)}`,
        },
        {
          headline: "Beskrivelse av tiltak",
          value: props.facilitationGrant.applicationDescription,
          light: true,
          class: "text",
          icon: "mdi-comment-arrow-right-outline",
        },
        {
          headline: "Beskrivelse av tilretteleggingskostnad",
          value: props.facilitationGrant.descriptionOfAdditionalCost,
          light: true,
          class: "text",
          icon: "mdi-comment-arrow-right-outline",
        },
        {
          headline: "Kommentar til tilsagn",
          value: props.facilitationGrant.commentToGrant,
          light: true,
          class: "text",
          icon: "mdi-comment-arrow-left-outline",
        },
        {
          headline: "Kommentar til kostnader",
          value: props.facilitationGrant.commentToActualCost,
          light: true,
          class: "text",
          icon: "mdi-comment-arrow-left-outline",
        },
      ]),
    };
  },
});
