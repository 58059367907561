var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm.courseId) + " " + _vm._s(_vm.courseName) + " - Søknad om tilretteleggingstilskudd")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.canCreateLinkedCourse ? _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "white",
            "outlined": "",
            "to": _vm.createLinkedCourseLink
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Opprett tilskuddskurs ")], 1) : _vm._e(), _vm.parentCourse ? _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "white",
            "outlined": "",
            "to": {
              name: _vm.SingleCourseRouteNames.CourseDashboard,
              params: {
                id: _vm.parentCourse.toString()
              }
            }
          }
        }, [_vm._v(" Til hovedkurs ")]) : _vm._e(), _vm.facilitationCourse ? _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "white",
            "outlined": "",
            "to": {
              name: _vm.SingleCourseRouteNames.CourseDashboard,
              params: {
                id: _vm.facilitationCourse.toString()
              }
            }
          }
        }, [_vm._v(" Til tilretteleggingskurs ")]) : _vm._e(), _c('v-btn', {
          staticClass: "ml-4",
          attrs: {
            "color": "white",
            "outlined": "",
            "to": "."
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" Tilbake til kursdashboard ")], 1)];
      },
      proxy: true
    }])
  }, [_c('GrantApplicationStepper', {
    attrs: {
      "courseId": _vm.courseId,
      "course": _vm.course,
      "facilitationGrant": _vm.facilitationGrant,
      "memberOrganization": _vm.memberOrganization
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }