var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.columns,
      "items": _vm.grantValues.budgetLines,
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns.map(function (_ref) {
      var value = _ref.value;
      return value;
    }), function (column) {
      return {
        key: "header.".concat(column),
        fn: function fn(_ref2) {
          var header = _ref2.header;
          return [_c('div', {
            key: "template-header-".concat(column)
          }, [_vm._v(" " + _vm._s(header.text) + " ")])];
        }
      };
    }), _vm._l(_vm.columns.map(function (_ref3) {
      var value = _ref3.value;
      return value;
    }), function (column) {
      return {
        key: "item.".concat(column),
        fn: function fn(_ref4) {
          var item = _ref4.item,
              header = _ref4.header;
          return [_c('div', {
            key: "template-".concat(column)
          }, [header.editable && item.budgetLineType !== undefined ? _c('v-text-field', {
            attrs: {
              "suffix": "kr",
              "reverse": "",
              "hide-details": "",
              "dense": ""
            },
            on: {
              "input": _vm.emitChanges
            },
            model: {
              value: item[column],
              callback: function callback($$v) {
                _vm.$set(item, column, _vm._n($$v));
              },
              expression: "item[column]"
            }
          }) : _c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(item[column])) + " ")])], 1)];
        }
      };
    }), {
      key: "item.budgetLineType",
      fn: function fn(_ref5) {
        var _ref5$item = _ref5.item,
            budgetLineType = _ref5$item.budgetLineType,
            description = _ref5$item.description;
        return [_c('div', [_vm._v(_vm._s(description || _vm.getDescription(budgetLineType)))])];
      }
    }, {
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "font-weight-bold"
        }, [_c('td', [_vm._v("Sum tilrettelegging")]), _vm._l(_vm.visibleBudgetColumns, function (column) {
          return _c('td', {
            key: "sum-cost-".concat(column.value),
            staticClass: "text-end"
          }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.sumCostType(column.value, _vm.grantValues.budgetLines))) + " ")]);
        })], 2)];
      },
      proxy: true
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }