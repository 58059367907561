import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { Ref, ref, toRefs, watch } from "@vue/composition-api";

function useAutoDeepCloneProp<PropNameType extends keyof PropsType, PropsType extends Record<PropNameType, unknown>>(
  props: PropsType,
  propName: PropNameType
): Ref<PropsType[PropNameType]>;

// Legacy implementation
function useAutoDeepCloneProp<RefType>(props: any, propName: string): Ref<RefType>;

function useAutoDeepCloneProp<PropNameType extends keyof PropsType, PropsType extends Record<PropNameType, unknown>>(
  props: PropsType,
  propName: PropNameType
): Ref<PropsType[PropNameType]> {
  const propsRef = toRefs(props);
  const myRef: Ref<PropsType[PropNameType]> = ref(deepCloneObject(propsRef[propName].value));

  // manually update courseValues with deep cloned version of props
  watch(
    propsRef[propName],
    () => {
      myRef.value = deepCloneObject(propsRef[propName].value);
    },
    { deep: true }
  );

  return myRef;
}

export { useAutoDeepCloneProp };
