var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('v-alert', {
    attrs: {
      "text": "",
      "type": "info"
    }
  }, [_vm._v(" Regnskapsrapport og dokumentasjon på faktiske ekstrakostnader må sendes inn før tilskudd kan utbetales. ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-file-input', {
    attrs: {
      "chips": "",
      "multiple": "",
      "show-size": "",
      "counter": "",
      "outlined": "",
      "accept": ".pdf,.png,.jpg,.jpeg,.xls,.xlsx",
      "label": "Velg en eller flere filer som dokumentasjon"
    },
    model: {
      value: _vm.filesToUpload,
      callback: function callback($$v) {
        _vm.filesToUpload = $$v;
      },
      expression: "filesToUpload"
    }
  }), _c('v-btn', {
    staticClass: "ml-4",
    attrs: {
      "color": "primary",
      "x-large": ""
    },
    on: {
      "click": _vm.uploadFiles
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-cloud-upload")]), _vm._v("Last opp")], 1)], 1), !_vm.isLoading ? _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "pb-0"
  }, [_vm._v("Vedlagte filer")]), _vm.uploadedFiles.length ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.uploadedFiles, function (file, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-file")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(file.originalFileName)
      }
    })], 1)], 1);
  }), 1) : _c('div', {
    staticClass: "ma-4"
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "warning"
    }
  }, [_vm._v(" Ingen filer er vedlagt. Regnskapsrapport og dokumentasjon på faktiske ekstrakostnader må sendes inn før tilskudd kan utbetales. ")])], 1)], 1) : _vm._e(), _vm.isLoading ? _c('v-overlay', {
    attrs: {
      "absolute": "",
      "color": "white",
      "opacity": ".7"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }