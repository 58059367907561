var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper', {
    attrs: {
      "value": _vm.activeStep,
      "flat": "",
      "vertical": ""
    }
  }, [_vm._l(_vm.steps, function (step) {
    return [_c('v-stepper-step', _vm._b({
      key: "step-".concat(step.index),
      attrs: {
        "step": step.index
      },
      on: {
        "click": function click($event) {
          return _vm.setStep(step);
        }
      }
    }, 'v-stepper-step', Object.assign({}, step.props, _vm.getStepProps(step)), false), [_vm._v(" " + _vm._s(step.title)), step.subtitle ? _c('small', [_vm._v(_vm._s(step.subtitle))]) : _vm._e()]), _c('v-stepper-content', {
      key: "step-content-".concat(step.index),
      attrs: {
        "step": step.index
      }
    }, [_vm.isStepVisible(step) ? _c('div', [_vm._l(_vm.filterEnabledItems(step.content), function (content, index) {
      return _c(content.component, _vm._g(_vm._b({
        key: "step-content-".concat(step.index, "-component-").concat(index),
        tag: "component",
        attrs: {
          "course": _vm.course,
          "facilitationGrant": _vm.grantValues,
          "memberOrganization": _vm.memberOrganization,
          "courseId": _vm.courseId,
          "disabled": step.disabled
        },
        on: {
          "grantChange": _vm.updateGrant
        }
      }, 'component', content.props, false), content.eventHandlers));
    }), _c('v-row', [_c('v-col', [step.actions ? _vm._l(_vm.filterEnabledItems(step.actions), function (action, index) {
      return _c('v-menu', {
        key: "step-".concat(step.index, "-action-").concat(index),
        attrs: {
          "offset-y": ""
        },
        scopedSlots: _vm._u([{
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
                attrs = _ref.attrs;
            return [_c('v-btn', _vm._g(_vm._b({
              staticClass: "mr-4"
            }, 'v-btn', action.confirm ? Object.assign({}, action.props, attrs) : action.props, false), action.confirm ? on : action.eventHandlers), [action.iconLeft ? _c('v-icon', {
              attrs: {
                "left": ""
              }
            }, [_vm._v(_vm._s(action.iconLeft))]) : _vm._e(), _vm._v(" " + _vm._s(action.label) + " "), action.iconRight ? _c('v-icon', {
              attrs: {
                "right": ""
              }
            }, [_vm._v(_vm._s(action.iconRight))]) : _vm._e()], 1)];
          }
        }], null, true)
      }, [_c('v-card', [_c('div', {
        staticClass: "pa-4"
      }, [_c('div', {
        staticClass: "mb-4"
      }, [_vm._v(_vm._s(action.confirm))]), _c('v-btn', [_vm._v("Avbryt")]), _c('v-btn', _vm._g(_vm._b({
        staticClass: "ml-4",
        attrs: {
          "color": "primary"
        }
      }, 'v-btn', action.props, false), action.eventHandlers), [_vm._v("Bekreft")])], 1)])], 1);
    }) : [step.index != _vm.steps[0].index ? _c('v-btn', {
      staticClass: "mr-4",
      attrs: {
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.prevStep();
        }
      }
    }, [_vm._v(" Forrige steg ")]) : _vm._e(), step.index != _vm.steps[_vm.steps.length - 1].index ? _c('v-btn', {
      attrs: {
        "disabled": step.valid !== undefined ? !step.valid : false,
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.nextStep();
        }
      }
    }, [_vm._v(" Neste steg "), _c('v-icon', {
      attrs: {
        "right": ""
      }
    }, [_vm._v("mdi-arrow-right")])], 1) : _vm._e()]], 2)], 1)], 2) : _vm._e()])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }