
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "GrantBudgetComment",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
    },
    hint: {
      type: String,
      default: undefined,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const text = ref("");
    const emitChanges = () => {
      emit("change", text.value);
    };

    return {
      emitChanges,
      text,
    };
  },
});
