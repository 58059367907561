
import { computed, defineComponent } from "@vue/composition-api";
import { GrantApplicationStatus } from "@/shared/enums/GrantApplicationStatus.enum";
import { commonProps } from "./commonProps";

export default defineComponent({
  name: "GrantApplicationSummary",
  props: {
    ...commonProps,
  },
  setup(props) {
    return {
      isApproved: computed(
        () => props.course?.facilitationGrantApplication?.applicationStatus === GrantApplicationStatus.CostApproved
      ),
      isRejected: computed(
        () => props.course?.facilitationGrantApplication?.applicationStatus === GrantApplicationStatus.Rejected
      ),
    };
  },
});
