import {
  ApiGetFacilitationGrantApplicationDto,
  ApiGetKursAdminCourseDto,
  ApiGetMemberOrganizationDto,
} from "@/api/generated/Api";
import { PropType } from "@vue/composition-api";

export const commonProps = {
  courseId: {
    type: Number,
    required: true,
  },
  facilitationGrant: {
    type: Object as PropType<ApiGetFacilitationGrantApplicationDto>,
    required: true,
  },
  memberOrganization: {
    type: Object as PropType<ApiGetMemberOrganizationDto>,
  },
  course: {
    type: Object as PropType<ApiGetKursAdminCourseDto>,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
} as const;
