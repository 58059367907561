
import { ApiFacilitationGrantBudgetLineType } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { FacilitationCostType, sumCostType } from "@/shared/helpers/courseHelpers";
import { formatCurrency } from "@/shared/helpers/formattingHelpers";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import { DataTableHeader } from "vuetify";
import { commonProps } from "./commonProps";

interface BudgetColumn extends Omit<DataTableHeader, "value"> {
  value: FacilitationCostType;
  visible?: boolean;
  editable?: boolean;
}

export default defineComponent({
  name: "GrantBudgetTable",
  props: {
    ...commonProps,
    visibleColumns: {
      type: Array as PropType<FacilitationCostType[]>,
    },
    editableColumns: {
      type: Array as PropType<FacilitationCostType[]>,
    },
  },
  emits: ["grantChange"],
  setup(props, { emit }) {
    const grantValues = useAutoDeepCloneProp(props, "facilitationGrant");

    const visibleBudgetColumns = budgetColumns
      .map((column) => ({
        ...column,
        visible: props.visibleColumns === undefined || props.visibleColumns.includes(column.value),
        editable: props.editableColumns !== undefined && props.editableColumns.includes(column.value),
        width: 150,
      }))
      .filter((column) => column.visible);

    const columns = computed((): BudgetColumn[] => [
      {
        text: "Beskrivelse",
        sortable: false,
        value: "budgetLineType",
      },
      ...visibleBudgetColumns,
    ]);

    const emitChanges = () => {
      emit("grantChange", grantValues.value);
    };

    return {
      formatCurrency,
      columns,
      visibleBudgetColumns,
      grantValues,
      sumCostType,
      getDescription,
      emitChanges,
    };
  },
});

const getDescription = (budgetLineType: number) => {
  const enumValue = Object.values(ApiFacilitationGrantBudgetLineType)[budgetLineType];

  const descriptions = {
    [ApiFacilitationGrantBudgetLineType.ApiExtraPersonnel]: "Ekstra personale",
    [ApiFacilitationGrantBudgetLineType.ApiFacilitationOfProgramAndMaterial]:
      "Tilrettelegging av kursprogram og undervisningsmateriell",
    [ApiFacilitationGrantBudgetLineType.ApiExtraPlanning]: "Ekstra planlegging, utvikling og oppfølging",
    [ApiFacilitationGrantBudgetLineType.ApiExtraEquipment]: "Ekstra utstyr",
    [ApiFacilitationGrantBudgetLineType.ApiExtraTransport]: "Ekstra eller tilrettelagt transport",
    [ApiFacilitationGrantBudgetLineType.ApiOtherCosts]: "Ev. andre kostnader ved tilretteleggingen",
  };

  return descriptions[enumValue];
};

const budgetColumns: BudgetColumn[] = [
  {
    text: "Søknad",
    sortable: false,
    value: "amountAppliedFor",
    align: "end",
  },
  {
    text: "Tilsagn",
    sortable: false,
    value: "amountGranted",
    align: "end",
  },
  {
    text: "Faktiske kostnader",
    sortable: false,
    value: "amountActualCost",
    align: "end",
  },
];
